<template>
  <FieldSet
      class="address-field-set">
    <template #title>
      <slot name="title" />
    </template>
    <slot name="before" />
    <div class="address-field-set__input address-field-set__input--address">
      <slot name="address">
        <Field
            :validator="validator.address"
            :required="!!validator.address.required"
            :label="t('address.label')"
            :help-text="t('address.helpText')"
            name="address">
          <template #default="{ id, fieldName }">
            <TextInput
                :id="id"
                v-model.lazy="validator.address.$model"
                :validator="validator.address"
                :name="fieldName" />
          </template>
          <template #error>
            <li v-if="validator.address.required.$invalid">
              {{ t('address.required') }}
            </li>
            <li
                v-for="external in validator.address.$externalResults"
                :key="external.$uid">
              {{ external.$message }}
            </li>
          </template>
        </Field>
      </slot>
    </div>
    <div class="address-field-set__input address-field-set__input--postal_code">
      <slot name="postal_code">
        <Field
            :validator="validator.postalCode"
            :required="!!validator.postalCode.required"
            :label="t('postalCode.label')"
            :help-text="
            (country && country.id === 'NL')
            ? t('postalCode.helpText'): ''"
            :size="12"
            name="postal_code">
          <template #default="{ id, fieldName }">
            <TextInput
                :id="id"
                v-model="validator.postalCode.$model"
                :validator="validator.postalCode"
                :name="fieldName" />
          </template>
          <template #error>
            <li v-if="validator.postalCode.required.$invalid">
              {{ t('postalCode.required') }}
            </li>
            <li v-if="validator.postalCode.validPostalCode.$invalid">
              {{ t('postalCode.valid') }}
            </li>
            <li
                v-for="external in validator.postalCode.$externalResults"
                :key="external.$uid">
              {{ external.$message }}
            </li>
          </template>
        </Field>
      </slot>
    </div>
    <div class="address-field-set__input address-field-set__input--city">
      <slot name="city">
        <Field
            :validator="validator.city"
            :required="!!validator.city.required"
            :label="t('city.label')"
            :size="40"
            name="city">
          <template #default="{ id, fieldName }">
            <TextInput
                :id="id"
                v-model="validator.city.$model"
                :validator="validator.city"
                :name="fieldName" />
          </template>
          <template #error>
            <li v-if="validator.city.required.$invalid">
              {{ t('city.required') }}
            </li>
          </template>
        </Field>
      </slot>
    </div>
    <div class="address-field-set__input address-field-set__input--country">
      <slot name="country">
        <Field
            :validator="validator.country"
            :required="!!validator.country.required"
            :label="t('country.label')"
            name="country">
          <template #default="{ id, fieldName }">
            <CountryInput
                :id="id"
                v-model="validator.country.$model"
                :name="fieldName" />
          </template>
          <template #error>
            <li v-if="validator.country.required.$invalid">
              {{ t('country.required') }}
            </li>
          </template>
        </Field>
      </slot>
    </div>
    <slot name="after" />
    <template #aside>
      <slot name="aside" />
    </template>
  </FieldSet>
</template>
<i18n>
{
  "nl": {
    "nominatimRequired": "Kan het adres niet vinden",
    "address": {
      "helpText": "Straatnaam en huisnummer",
      "label": "Adres",
      "required": "Een adres is verplicht"
    },
    "postalCode": {
      "helpText": "1234 AB",
      "label": "Postcode",
      "required": "Een postcode is verplicht",
      "valid": "Vul een postcode in de vorm 0000 AA in"
    },
    "city": {
      "label": "Plaats",
      "required": "Een plaats is verplicht."
    },
    "country": {
      "NL": "Nederland",
      "label": "Land",
      "required": "Een land is verplicht."
    }
  },
  "en": {
    "nominatimRequired": "Could not find the address",
    "address": {
      "helpText": "Street name and address",
      "label": "Address",
      "required": "An address is required"
    },
    "postalCode": {
      "helpText": "1234 AB",
      "label": "Postal code",
      "required": "A postal code is required",
      "valid": "A Dutch postal code looks like 0000 AA."
    },
    "city": {
      "label": "City",
      "required": "A city is required."
    },
    "country": {
      "NL": "Netherlands",
      "label": "Country",
      "required": "A country is required."
    }
  }
}
</i18n>
<script>
import {
  computed,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useQuery } from '@urql/vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import gql from 'graphql-tag';
import FieldSet from './FieldSet.vue';

import Field from '../fields/FormField.vue';
import TextInput from '../widgets/forms/TextInput.vue';
import ListInput from '../forms/ListInput.vue';
import Loader from '../loading/LoaderPane.vue';
import {
  toModelValueRefs,
  useDebouncedRef,
} from '../../utils/refs.js';
import { nominatimQuery } from '../../utils/address.js';
import CountryInput from '../address/CountryInput.vue';

// TODO: show a loader when checking Nominatim

export default {
  components: {
    CountryInput,
    TextInput,
    Field,
    FieldSet,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => null,
    },
    externalResults: {
      type: Object,
      default: () => null,
    },
    isSelf: {
      type: Boolean,
      default: () => false,
    },
    required: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['update:model-value'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const { modelValue, required: locationRequired, externalResults } = toRefs(props);
    const nominatimValidation = ref({});

    const longitude = ref(null);
    const latitude = ref(null);
    // Create a debounced ref which triggers external validation
    const query = useDebouncedRef(modelValue.value, 1500);

    watch(modelValue, (v) => {
      query.value = v;
    });

    const {
      address, postalCode, city, country,
    } = toModelValueRefs(modelValue, emit, ['address', 'postalCode', 'city', 'country']);

    watch(query, async (q) => {
      if (q && q.address && q.postalCode && q.city && q.country) {
        const result = await nominatimQuery(q.address, q.postalCode, q.city, q.country);
        if (!result.latitude) {
          nominatimValidation.value = { address: [t('nominatimRequired')] };
        } else {
          nominatimValidation.value = {};
          latitude.value = result.latitude;
          longitude.value = result.longitude;
          if (result.address && result.address !== address.value) {
            address.value = result.address;
          }
          if (result.postalCode && result.postalCode !== postalCode.value) {
            postalCode.value = result.postalCode;
          }
          if (result.city && result.city !== city.value) {
            city.value = result.city;
          }
        }
      }
    });

    const rules = {
      address: {
        required: requiredIf(computed(() => locationRequired.value
            || postalCode.value || city.value)),
        $lazy: true,
      },
      postalCode: {
        required: requiredIf(computed(() => locationRequired.value || address.value || city.value)),
        validPostalCode: (value) => !value || (country.value?.id !== 'NL' || /^\d{4}\u0020[a-z]{2}$/i.test(value.trim())),
        $lazy: true,
      },
      city: {
        required: requiredIf(computed(() => postalCode.value || address.value)),
        $lazy: true,
      },
      country: {
        required: requiredIf(computed(() => postalCode.value || address.value || city.value)),
        $lazy: true,
      },
    };
    const validator = useVuelidate(rules, {
      address, postalCode, city, country,
    }, {
      $externalResults: computed(() => ({ ...nominatimValidation, ...externalResults.value })),
    });

    return {
      t,
      validator,
      address,
      postalCode,
      city,
      country,
      latitude,
      longitude,
      query,
    };
  },

};
</script>
