<template>
  <FieldSet class="person-field-set">
    <template #title>
      <slot name="title" />
    </template>
    <slot />
    <Field
        :label="t('givenName.label')"
        :validator="validator.givenName"
        :required="!!validator.givenName.required"
        :size="35"
        name="givenName">
      <template #default="{id, fieldName}">
        <TextInput
            :id="id"
            ref="givenNameInput"
            v-model="validator.givenName.$model"
            :validator="validator.givenName"
            autofocus="autofocus"
            :name="fieldName" />
      </template>
      <template #error>
        <li v-if="validator.givenName.required.$invalid">
          {{ t('givenName.requiredYou') }}
        </li>
      </template>
    </Field>

    <Field
        :label="t('preposition.label')"
        :validator="validator.preposition"
        :size="15"
        name="preposition">
      <template #default="{id, fieldName}">
        <TextInput
            :id="id"
            v-model="validator.preposition.$model"
            :validator="validator.preposition"
            :name="fieldName" />
      </template>
    </Field>

    <Field
        :label="t('surname.label')"
        :validator="validator.surname"
        :required="true"
        name="surname">
      <template #default="{id, fieldName}">
        <TextInput
            :id="id"
            v-model="validator.surname.$model"
            :validator="validator.surname"
            :name="fieldName" />
      </template>
      <template #error>
        <li v-if="validator.surname.required.$invalid">
          {{ t('surname.requiredYou') }}
        </li>
      </template>
    </Field>
</FieldSet>
</template>
<i18n>
{
  "nl": {
    "givenName": {
      "label": "Voornaam",
      "required": "Vul een voornaam in",
      "requiredYou": "Vul jouw voornaam in"
    },
    "preposition": {
      "label": "Tussenvoegsel"
    },
    "surname": {
      "label": "Achternaam",
      "required": "Vul een achternaam in",
      "requiredYou": "Vul jouw achternaam in"
    }
  },
  "en": {
    "givenName": {
      "label": "Given name",
      "required": "Enter a given name",
      "requiredYou": "Enter your given name"
    },
    "preposition": {
      "label": "Preposition"
    },
    "surname": {
      "label": "Surname",
      "required": "Enter a surname",
      "requiredYou": "Enter your surname"
    }
  }
}
</i18n>
<script>
import {
  computed,
  onMounted,
  ref,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import FieldSet from './FieldSet.vue';
import Field from '../fields/FormField.vue';
import TextInput from '../widgets/forms/TextInput.vue';
import { toModelValueRefs } from '../../utils/refs.js';

export default {
  components: {
    TextInput,
    Field,
    FieldSet,
  },
  props: {
    isSelf: {
      type: Boolean,
      default: () => false,
    },
    externalResults: {
      type: Object,
      default: () => null,
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const { modelValue, externalResults } = toRefs(props);

    const givenNameInput = ref(null);
    const { t } = useI18n();

    const {
      givenName, surname, preposition,
    } = toModelValueRefs(
      modelValue,
      emit,
      ['givenName', 'surname', 'preposition'],
    );

    const rules = computed(() => ({
      givenName: {
        required,
      },
      preposition: {},
      surname: {
        required,
      },

    }));

    const validator = useVuelidate(rules, {
      givenName, surname, preposition,
    }, { $externalResults: externalResults });

    onMounted(() => {
      givenNameInput.value?.$el.focus();
    });

    return {
      t,
      validator,
      givenName,
      preposition,
      surname,
    };
  },
};
</script>
