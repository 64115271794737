<template>
  <div
      v-if="user"
      class="user-widget">
    <template v-if="user.person">
      <slot name="userIcon">
        <UserIcon :title="t('user')" />
      </slot>
      <TextLabel
          tag="div">
        {{ user.person.fullName }}
      </TextLabel>
    </template>
    <template v-else-if="!user.isStaff">
      <slot name="groupIcon">
        <GroupIcon :title="t('group')" />
      </slot>
      <TextLabel
          :selectable="true"
          tag="div">
        {{ user.email }}
      </TextLabel>
    </template>
    <template v-else-if="user.isStaff">
      <slot name="staffIcon">
        <StaffIcon :title="t('staff')" />
      </slot>
      <TextLabel
          :selectable="true"
          tag="div">
        {{ user.email }}
      </TextLabel>
    </template>
  </div>
</template>
<i18n>
{
  "nl": {
    "staff": "Kantoormedewerker",
    "user": "Gebruiker",
    "group": "Groep"
  },
  "en": {
    "staff": "Office employee",
    "user": "User",
    "group": "Group"
  }
}
</i18n>
<script>
import UserIcon from '@carbon/icons-vue/es/user/16.js';
import GroupIcon from '@carbon/icons-vue/es/events--alt/16.js';
import StaffIcon from '@carbon/icons-vue/es/security/16.js';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import TextLabel from '../typography/TextLabel.vue';
import useSession from '../../session/session.js';

export default {
  components: {
    TextLabel, UserIcon, GroupIcon, StaffIcon,
  },
  props: {
    user: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    const { t } = useI18n();
    const session = useSession();
    return {
      t,
      isStaff: computed(() => session.isStaff),
    };
  },
};
</script>
<style>
.user-widget {
    display: inline-flex;
    gap: var(--dimension-x-small);
    flex-direction: row;
}
</style>
