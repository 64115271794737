<template>
  <ObjectSearchPane
      ref="search"
      v-model:q="q"
      :results="results"
      :loading="loading"
      @select="go">
    <template #item="{item}">
      <IconLabel v-if="item.__typename === 'Person'">
        <template #icon>
          <PersonIcon />
        </template>
        {{ item.fullName }}
      </IconLabel>
      <IconLabel v-else-if="item.__typename === 'Combination'">
        <template #icon>
          <DogIcon />
        </template>
        {{ item.handler.fullName }} + {{ item.dog.callingName }}
      </IconLabel>
      <IconLabel v-else-if="item.__typename === 'Club'">
        <template #icon>
          <ClubIcon />
        </template>
        {{ item.name }}
      </IconLabel>
      <IconLabel v-else-if="item.__typename === 'Dog'">
        <template #icon>
          <DogIcon />
        </template>
        {{ item.callingName }}
        – {{ item.primaryContact.fullName }}
      </IconLabel>
    </template>
  </ObjectSearchPane>
</template>
<script>
import DogIcon from '@carbon/icons-vue/es/dog-walker/16.js';
import PersonIcon from '@carbon/icons-vue/es/user/16.js';
import ClubIcon from '@carbon/icons-vue/es/user--multiple/16.js';
import { useI18n } from 'vue-i18n';
import {
  computed,
  ref,
} from 'vue';
import gql from 'graphql-tag';
import { useQuery } from '@urql/vue';
import { useRouter } from 'vue-router';
import IconLabel from '../typography/IconLabel.vue';
import ObjectSearchPane from './ObjectSearchPane.vue';
import { useDebouncedRef } from '../../utils/refs.js';

const SEARCH_QUERY = gql`
query Search($q: String!) {
  licenseSearch(q: $q) {
    __typename
    ... on Dog {
      slug
      chipNumber
      callingName
      primaryContact {
        id
        slug
        fullName
      }
    }
    ... on Person {
      id
      slug
      fullName
    }
    ... on Combination {
      id
      number
      handler {
        id
        slug
        fullName
      }
      dog {
        id
        callingName
      }
    }
    ... on Club {
      id
      name
      slug
    }
  }

}`;

export default {
  components: {
    ObjectSearchPane,
    IconLabel,
    PersonIcon,
    DogIcon,
    ClubIcon,
  },
  props: {},
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();

    const q = useDebouncedRef('', 300, false);
    const search = ref(null);

    const searchQuery = useQuery({
      query: SEARCH_QUERY,
      requestPolicy: 'network-only',
      variables: { q },
      pause: computed(() => q.value.length < 3),
    });

    const results = computed(() => searchQuery.data.value?.licenseSearch || []);

    const go = async (item) => {
      if (item.__typename === 'Person') {
        await router.push(
          { name: `${locale.value}:admin:person`, params: { person: item.slug } },
        );
      } else if (item.__typename === 'Club') {
        await router.push(
          { name: `${locale.value}:club:manage`, params: { club: item.slug } },
        );
      } else if (item.__typename === 'Dog') {
        await router.push(
          {
            name: `${locale.value}:admin:person:dog`,
            params: {
              person: item.primaryContact.slug,
              dog: item.slug,
            },
          },
        );
      } else if (item.__typename === 'Combination') {
        await router.push(
          {
            name: `${locale.value}:admin:person:combination`,
            params: {
              person: item.handler.slug,
              combination: item.number,
            },
          },
        );
      }
    };

    return {
      t,
      loading: computed(() => !!(searchQuery.fetching.value || searchQuery.isPaused.value)),
      go,
      search,
      focus() {
        search.value?.focus();
      },

      q,
      results,
    };
  },
};
</script>
