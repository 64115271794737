<template>
  <IconLabel class="team-label">
    <template #icon>
      <TeamIcon />
    </template>
    <HeaderTitle
        tag="span"
        :title="team.name">
      {{ team.name }}
    </HeaderTitle>
    <slot />
  </IconLabel>
</template>
<script>
import TeamIcon from '@carbon/icons-vue/es/user--multiple/16.js';
import IconLabel from '../typography/IconLabel.vue';
import HeaderTitle from '../typography/HeaderTitle.vue';

export default {
  components: {
    HeaderTitle,
    IconLabel,
    TeamIcon,
  },
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
};
</script>
