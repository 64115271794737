<template>
  <ul
      ref="el"
      class="list-container">
    <slot name="before" />
    <slot name="list">
      <template
          v-for="item in items"
          :key="keyGetter(item)">
        <slot
            name="item"
            :item="item">
          <li
              class="list-container__item">
            <slot
                :item="item"
                :id-getter="keyGetter"
                :item-id="keyGetter(item)" />
          </li>
        </slot>
      </template>
    </slot>
    <slot
        v-if="!items?.length"
        name="empty" />
    <slot name="after" />
  </ul>
</template>
<script>

import {
  ref,
  toRefs,
} from 'vue';

export default {
  props: {
    idField: {
      type: [String, null],
      default: () => 'id',
    },
    items: {
      type: Array,
      default: () => [],
      required: false,
    },
    idGetter: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const { idGetter, idField } = toRefs(props);
    const el = ref(null);

    const keyGetter = idGetter.value || ((item) => ((idField.value)
      ? (item && item[idField.value])
      : item)
    );

    return {
      el,
      keyGetter,
      scrollToItem(index) {
        el.value?.children[index]?.scrollIntoView({ block: 'nearest' });
      },
    };
  },
};
</script>

<style>
.list-container {
  display: grid;
  grid-auto-flow: row;

  align-content: flex-start;


  &__empty,
  &__item {
    display: block;
  }

  &__empty {
    font-style: italic;
  }
}
</style>
