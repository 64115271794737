<template>
  <label
      class="radio-box"
      :class="{
        'radio-box--disabled': disabled,
        'radio-box--narrow': narrow,
    }"
      @click.stop="">
    <input
        :id="id"
        v-bind="$attrs"
        v-model="internalValue"
        class="radio-box__input"
        :value="value"
        :name="name"
        type="radio">
    <span class="radio-box__label">
      <slot
          name="content"
          :for="id">
        <TextLabel
            tag="span"
            :for="id">
          <slot :label="label">
            {{ label }}
          </slot>
        </TextLabel>
      </slot>
    </span>
  </label>
</template>

<script>
import {
  computed,
  toRefs,
} from 'vue';
import TextLabel from '../../typography/TextLabel.vue';

export default {
  components: { TextLabel },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: () => null,
    },
    value: {
      type: String,
      default: () => null,
    },
    id: {
      type: String,
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => '',
    },
    narrow: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['update:modelValue', 'check', 'uncheck'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    return {
      internalValue: computed({
        get() {
          return modelValue.value;
        },
        set: (v) => {
          if (v !== modelValue.value) {
            emit('update:modelValue', v);
          }
        },
      }),
    };
  },
};
</script>
<style>
.radio-box {
  display: inline-grid;
  grid-template-columns: min-content 1fr;
  gap: var(--dimension-small);
  align-items: flex-start;

  padding: var(--dimension-small);

  cursor: pointer;

  &--disabled {
    opacity: .5;
    cursor: auto;
  }

  &--narrow {
    padding: 0;
  }

  &__label {
    display: block;

    padding: var(--dimension-x-small) 0;
  }

  &__input {
    @media (any-hover: hover) {
      &:hover {
        background-image: linear-gradient(
            to bottom,
            rgba(var(--rgb-default-foreground), .075) 0,
            rgba(var(--rgb-default-foreground), .075) 50%,
            var(--color-primary-background) 50%,
            var(--color-primary-background) 100%
        );
        background-position: bottom center;

      }

    }
    position: relative;

    width: var(--dimension-medium-large);
    height: var(--dimension-medium-large);

    margin: 0;

    background-image: linear-gradient(
        to bottom,
        rgba(var(--rgb-default-foreground), .05) 0,
        rgba(var(--rgb-default-foreground), .05) 50%,
        var(--color-primary-background) 50%,
        var(--color-primary-background) 100%
    );
    background-position: top center;
    background-size: 100% 200%;

    border-radius: 50%;
    outline: none;

    cursor: pointer;

    vertical-align: middle;

    transition: background-position var(--animation-default-duration) linear;

    appearance: none;

    &::after {
      position: relative;
      display: block;
      content: "✅";

      width: 100%;
      height: 100%;

      color: transparent;

      background-color: rgba(var(--rgb-default-foreground), .15);
      /*noinspection CssUnknownTarget*/
      mask: url("@carbon/icons/svg/circle-fill.svg");
      mask-repeat: no-repeat;
      mask-size: 1rem 1rem;
      mask-position: center center;

      transition: background-color calc(var(--animation-default-duration) / 2) linear;
    }

    &:checked {
      background-position: bottom center;

      &::after {
        background-color: var(--color-default-background);
      }
    }
  }

  &--disabled &__input {
    cursor: auto;
  }

}

</style>
