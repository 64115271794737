<template>
  <InteractiveTableRow
      ref="root"
      class="sortable-table-row"
      :class="{
        'sortable-table-row--placeholder': placeholder,
        'sortable-table-row--dragging': isDragging,
        }"
      :enabled="interactive"
      :index="index"
      @click="v => $emit('click', v)">
    <TableCell
        v-if="dragEnabled"
        class="sortable-table-row__handle-column"
        type="narrow">
      <DraggableHandle
          ref="handle"
          :item="item"
          :enabled="draggable && !dragging"
          :context="context" />
    </TableCell>
    <slot />
  </InteractiveTableRow>
</template>
<script>
import {
  computed,
  ref,
  toRefs,
  watch,
} from 'vue';
import InteractiveTableRow from './InteractiveTableRow.vue';
import TableCell from './TableCell.vue';
import DraggableHandle from '../draggable/DraggableHandle.vue';
import useSortable from '../draggable/sortable.js';

export default {
  components: {
    DraggableHandle,
    TableCell,
    InteractiveTableRow,
  },

  props: {
    ...InteractiveTableRow.props,
    interactive: {
      type: Boolean,
      default: () => true,
    },
    item: {
      type: Object,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: () => true,
    },
    context: {
      type: Object,
      default: () => null,
    },
    anchored: {
      type: String,
      default: () => null,
    },
    dragEnabled: {
      type: Boolean,
      default: () => true,
    },
    placeholder: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: InteractiveTableRow.emits,

  setup(props) {
    const {
      draggable, context, contentType, item, anchored,
    } = toRefs(props);

    const handle = ref(null);
    const root = ref(null);

    const { isDragging, dragOffset } = useSortable(
      root,
      handle,
      item,
      contentType,
      draggable,
      anchored,
      context,
    );

    watch(isDragging, (n, o) => {
      if (n && !o) {
        context.value.set(contentType.value, item.value, dragOffset.value);
      } else if (!n && o) {
        context.value.cancel();
      }
    });

    return {
      handle, root, isDragging, dragging: computed(() => context.value.dragging.value),
    };

  },

};
</script>
<style>
.sortable-table-row {
  --color-placeholder: var(--color-sun-flower);

  &__handle-column {
    width: var(--dimension-medium-large);
    padding: 0;

    text-align: center;
  }

  &--placeholder {
    --table-row-background: var(--color-placeholder);
    --table-row-alternate-background: var(--color-placeholder);
  }

  &--dragging {
    --list-row-background: var(--color-placeholder);
    --list-row-alternate-background: var(--color-placeholder);
    opacity: .5;
  }

  &--dragging:is(&--placeholder) {
    opacity: 1;
  }
}
</style>
