<template>
  <InteractiveListItem
      ref="root"
      :enabled="interactive"
      class="sortable-list-item"
      :class="{
        'sortable-list-item--placeholder': placeholder,
        'sortable-list-item--dragging': isDragging,
      }"
      @click="$emit('click')">
    <DraggableHandle
        v-if="dragEnabled"
        ref="handle"
        class="sortable-list-item__handle"
        :item="item"
        :context="context"
        :enabled="draggable && !dragging" />
    <slot />
  </InteractiveListItem>
</template>
<script>
import {
  computed,
  ref,
  toRefs,
  watch,
} from 'vue';
import InteractiveListItem from './InteractiveListItem.vue';
import useSortable from '../draggable/sortable.js';
import DraggableHandle from '../draggable/DraggableHandle.vue';

export default {
  components: { DraggableHandle, InteractiveListItem },
  props: {
    item: {
      type: Object,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    },
    interactive: {
      type: Boolean,
      default: () => true,
    },
    draggable: {
      type: Boolean,
      default: () => true,
    },
    context: {
      type: Object,
      default: () => null,
    },
    anchored: {
      type: String,
      default: () => null,
    },
    dragEnabled: {
      type: Boolean,
      default: () => true,
    },
    placeholder: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['click'],
  setup(props) {
    const {
      draggable, context, contentType, item, anchored,
    } = toRefs(props);

    const handle = ref(null);
    const root = ref(null);

    const { isDragging, dragOffset } = useSortable(
      root,
      handle,
      item,
      contentType,
      draggable,
      anchored,
      context,
    );

    watch(isDragging, (n, o) => {
      if (n && !o) {
        context.value?.set(contentType.value, item.value, dragOffset.value);
      } else if (!n && o) {
        context.value?.cancel();
      }
    });
    return {
      handle, root, isDragging, dragging: computed(() => context.value?.dragging.value),
    };

  },
};
</script>
<style>
.sortable-list-item {
  --color-placeholder: var(--color-sun-flower);

  &__handle {
    text-align: center;
  }

  &--placeholder {
    --list-item-background: var(--color-placeholder);
    --list-item-alternate-background: var(--color-placeholder);
  }

  &--dragging {
    --list-item-background: var(--color-placeholder);
    --list-item-alternate-background: var(--color-placeholder);
    opacity: .5;
  }

  &--dragging:is(&--placeholder) {
    opacity: 1;
  }
}
</style>
