<template>
  <Panel
      class="flow-panel"
      :class="{'flow-panel--interactive': interactive}">
    <template #header>
      <div
          v-if="!loading"
          class="flow-panel__header">
        <slot
            v-if="!!$slots.title || title"
            name="title">
          <PanelTitle>
            {{ title }}
          </PanelTitle>
        </slot>
        <ScrollablePane direction="horizontal">
          <ul class="flow-panel__steps">
            <TextLabel
                v-for="(step, idx) in visibleSteps"
                :key="idx"
                tag="li"
                class="flow-panel__step"
                :data-step="step.id"
                :class="{
              'flow-panel__step--enabled': step.enabled,
              'flow-panel__step--disabled': !step.enabled,
              'flow-panel__step--completed': step.completed,
              'flow-panel__step--active': modelValue === step.id,
            }"
                @click="select(step)">
              <component
                  :is="step.title"
                  v-if="step.title" />
              <CheckIcon
                  v-if="step.completed"
                  class="flow-panel__step-completed" />
              <component
                  :is="step.icon"
                  v-if="step.icon" />
            </TextLabel>
          </ul>
        </ScrollablePane>
      </div>
    </template>

    <template
        v-if="!!$slots.actions"
        #actions>
      <slot name="actions" />
    </template>

    <template #default>
      <DialogActionPane :scrollable="false">
        <PagerPane
            ref="pager"
            :model-value="modelValue"
            :tab-ids="steps.map(s=>s.id)"
            @update:model-value="v => $emit('update:model-value', v)">
          <template #default="{index}">
            <slot
                :index="index"
                :next="next"
                :previous="previous" />
          </template>
        </PagerPane>

        <template
            v-if="currentStep"
            #actions>
          <slot
              name="previous"
              :next="next"
              :previous="previous">
            <component
                :is="currentStep.previous"
                v-if="currentStep.previous"
                :hasNext="hasNext"
                :hasPrevious="hasPrevious"
                :next="next"
                :previous="previous" />
            <IconButton
                v-else-if="hasPrevious"
                :enabled="hasPreviousEnabled"
                :reversed="true"
                type="secondary-alt"
                @click="previous">
              {{ t('previous') }}
            </IconButton>
          </slot>
        </template>

        <template
            v-if="currentStep"
            #buttons>
          <slot
              name="next"
              :next="next"
              :previous="previous">
            <component
                :is="currentStep.next"
                v-if="currentStep.next"
                :hasNext="hasNext"
                :hasPrevious="hasPrevious"
                :next="next"
                :previous="previous" />
            <IconButton
                v-else-if="hasNext"
                :enabled="hasNextEnabled"
                @click="next">
              {{ t('next') }}
            </IconButton>
          </slot>
        </template>
      </DialogActionPane>
    </template>
  </Panel>
</template>
<i18n>
{
  "nl": {
    "next": "Volgende",
    "previous": "Vorige"
  },
  "en": {
    "next": "Next",
    "previous": "Previous"
  }
}
</i18n>
<script>
import CheckIcon from '@carbon/icons-vue/es/checkmark/16.js';
import {
  computed,
  nextTick,
  onMounted,
  provide,
  ref,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Panel from '../panels/Panel.vue';
import TextLabel from '../typography/TextLabel.vue';
import DialogActionPane from '../panels/DialogActionPane.vue';
import IconButton from '../buttons/IconButton.vue';
import PagerPane from './PagerPane.vue';
import PanelTitle from '../panels/PanelTitle.vue';
import ScrollablePane from '../panels/ScrollablePane.vue';

export const FLOW_PANEL_CONTROLLER_KEY = 'flowStepController';

export default {
  components: {
    ScrollablePane,
    PanelTitle,
    PagerPane,
    IconButton,
    DialogActionPane,
    TextLabel,
    Panel,
    CheckIcon,
  },
  props: {
    ...Panel.props,
    modelValue: {
      type: [String, null],
      default: () => null,
      required: true,
    },
    interactive: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['update:model-value', 'previous', 'next'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { modelValue, interactive } = toRefs(props);

    const steps = ref([]);

    const visibleSteps = computed(() => steps.value.filter((s) => s.visible));
    const enabledSteps = computed(() => steps.value.filter((s) => s.enabled && s.visible));

    const previous = async () => {
      await nextTick(); // previous is often called directly after enabling/disabling a step
      const idx = Math.max(
        0,
        enabledSteps.value.map((s) => s.id).indexOf(modelValue.value) - 1,
      );
      const target = enabledSteps.value[idx].id;
      emit('previous', target);
      emit('update:model-value', target);
    };
    const next = async () => {
      await nextTick(); // next is often called directly after enabling a new step
      const idx = Math.min(
        enabledSteps.value.length - 1,
        enabledSteps.value.map((s) => s.id).indexOf(modelValue.value) + 1,
      );
      const target = enabledSteps.value[idx]?.id;
      if (target) {
        emit('next', target);
        emit('update:model-value', target);
      }
    };

    provide(FLOW_PANEL_CONTROLLER_KEY, {
      register(step) {
        const idx = steps.value.indexOf(step);
        if (idx !== -1) {
          steps.value.splice(idx, 1, step);
        } else {
          steps.value.push(step);
        }
      },
      unregister(step) {
        const idx = steps.value.indexOf(step);
        if (idx !== -1) {
          steps.value.splice(idx, 1);
        }
      },
      previous,
      next,
      steps,
    });

    const hasNext = computed(() => visibleSteps.value
      .map((s) => s.id)
      .indexOf(modelValue.value) < visibleSteps.value.length - 1);
    const hasNextEnabled = computed(() => !!(hasNext.value && enabledSteps.value
      .map((s) => s.id)
      .indexOf(modelValue.value) < enabledSteps.value.length - 1));

    const hasPrevious = computed(() => visibleSteps.value
      .map((s) => s.id)
      .indexOf(modelValue.value) > 0);
    const hasPreviousEnabled = computed(() => !!(hasPrevious.value && enabledSteps.value
      .map((s) => s.id)
      .indexOf(modelValue.value) > 0));

    onMounted(() => {
      if (modelValue.value === null && steps.value?.length > 0) {
        const first = steps.value.find(s => s.visible && s.enabled && !s.completed)
        if (first) {
          emit('update:model-value', first.id);
        } else {
          const second = steps.value.find(s => s.visible && s.enabled)
          if (second) {
            emit('update:model-value', second.id);
          }
        }
      }
    });

    return {
      t,

      steps,
      visibleSteps,
      currentStep: computed(() => modelValue.value
          && steps.value?.find((s) => s.id === modelValue.value)),
      select(step) {
        if (step.enabled && interactive.value) {
          const currentIdx = (modelValue.value)
            ? enabledSteps.value.map((s) => s.id).indexOf(modelValue.value) : -1;
          const nextIdx = enabledSteps.value.map((s) => s.id).indexOf(step.id);
          if (nextIdx > currentIdx) {
            emit('next', step.id);
          } else if (nextIdx < currentIdx) {
            emit('previous', step.id);
          }
          emit('update:model-value', step.id);
        }
      },
      hasPrevious,
      hasPreviousEnabled,
      hasNext,
      hasNextEnabled,
      next,
      previous,

    };
  },
};
</script>
<style>
.flow-panel {
  flex: 1;

  &__steps {
    display: grid;
    grid-auto-flow: column;
    gap: var(--dimension-x-small);
    justify-content: flex-start;

    padding: var(--dimension-small) var(--dimension-medium);

    list-style: none;

    counter-reset: flow-steps;
  }

  &__step {
    display: flex;
    gap: var(--dimension-x-small);
    align-items: center;

    padding: var(--dimension-small);

    background-color: var(--color-white);

    &--disabled {
      opacity: .5;
      cursor: not-allowed;
    }

    &--completed {
      color: var(--color-white);
      background-color: var(--color-success);
    }

    &--active {
      color: var(--color-white);
      background-color: var(--color-primary-background);
    }

    &--enabled {
      opacity: 1;
    }

    &::before {
      content: counter(flow-steps) ": ";

      counter-increment: flow-steps;
    }
  }

  &--interactive &__step--enabled {
    cursor: pointer;
  }

}
</style>
