<template>
  <div
      class="badge"
      :class="{[`badge--${type}`]: true, 'badge--inline': inline}">
    <div class="badge__content">
      <slot />
    </div>

    <TextLabel
        v-if="badge !== '' || !!$slots.badge"
        class="badge__badge">
      <slot name="badge">
        {{ badge }}
      </slot>
    </TextLabel>
  </div>
</template>
<script>

import TextLabel from '../typography/TextLabel.vue';

export default {
  components: { TextLabel },
  props: {
    type: {
      type: String,
      default: () => 'primary',
    },
    inline: {
      type: Boolean,
      default: () => false,
    },
    badge: {
      type: [String, Number],
      default: () => '',
    },
  },
};
</script>
<style>

.badge {
  position: relative;
  display: grid;

  &__badge {
    position: absolute;

    top: calc(-1 * var(--dimension-medium) / 2);
    right: calc(-1 * var(--dimension-medium) / 2);

    z-index: var(--layer-popover);
    display: flex;
    justify-content: center;
    align-items: center;

    width: var(--dimension-medium);
    height: var(--dimension-medium);

    color: var(--color-white);
    background-color: var(--color-secondary-background);

    border-radius: 50%;

    font-size: .5rem;

    &:empty {
      display: none;
    }
  }

  &--inline {
    display: inline-flex;
    gap: var(--dimension-x-small);
    align-items: center;
  }

  &--inline &__badge {
    position: static;
    z-index: inherit;
  }

  &__content {
    display: grid;
    flex: 1;
  }

  &--inline &__content {
    display: block;
  }

  &--error &__badge {
    color: var(--color-white);
    background-color: var(--color-error);
  }

  &--info &__badge {
    color: var(--color-charcoal);
    background-color: var(--color-secondary-complement-background);

  }
}

</style>
