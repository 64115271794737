import {
  onBeforeUnmount,
  onMounted,
  onUpdated,
  ref,
  toRef,
  watchEffect,
} from 'vue';

const toggleAttribute = (el, attribute, value) => {
  if (value) {
    el.setAttribute(attribute, `${value}`);
  } else {
    el.removeAttribute(attribute);
  }
};

const isElementOrChild = (a, b) => a && b && (a === b || isElementOrChild(a, b.parentNode));

export default function useSortable(
  root,
  handle,
  item,
  contentType,
  draggable = true,
  anchored = null,
  context = null,
) {
  const rootRef = toRef(root);
  const handleRef = toRef(handle);
  const draggableRef = toRef(draggable);
  const anchoredRef = toRef(anchored);
  const itemRef = toRef(item);
  const contentTypeRef = toRef(contentType);
  const isDragging = ref(false);
  const dragOffset = ref(null);

  let target = null;

  const clickStart = (ev) => {
    if (draggableRef.value) {
      target = ev.target;
    }
  };

  const dragStart = (ev) => {
    const handleEl = handleRef.value?.$el || handleRef.value;

    if (handleEl && target && !isElementOrChild(handleEl, target)) {
      ev.preventDefault();
    } else {
      // eslint-disable-next-line no-param-reassign
      ev.dataTransfer.effectAllowed = 'move';
      ev.dataTransfer.setData(`application/x-cymbal-${contentTypeRef.value}`, JSON.stringify({
        contentType: contentTypeRef.value,
        item: itemRef.value,
      }));
      isDragging.value = true;
      dragOffset.value = ev.layerY;

      if (handleEl) {
        handleEl.classList.add('draggable-handle--dragging');
      }

    }
  };

  const dragEnd = () => {
    isDragging.value = false;
    target = null;
    const handleEl = handleRef.value?.$el || handleRef.value;
    if (handleEl) {
      handleEl.classList.remove('draggable-handle--dragging');
    }
  };

  onMounted(() => {
    const rootEl = rootRef.value?.$el || rootRef.value;

    if (rootEl) {
      rootEl.addEventListener('dragstart', dragStart);
      rootEl.addEventListener('dragend', dragEnd);

      rootEl.addEventListener('mousedown', clickStart);
      rootEl.classList.add('draggable-handle-root');

      watchEffect(() => {
        toggleAttribute(rootEl, 'draggable', draggableRef.value);
        rootEl.dataset.draggableAnchored = anchoredRef.value;
        rootEl.dataset.draggableContentType = contentTypeRef.value;
        rootEl.dataset.draggableContentId = item.value?.id;
      });
    }

  });

  onUpdated(() => {
    const rootEl = rootRef.value?.$el || rootRef.value;
    if (rootEl) {
      toggleAttribute(rootEl, 'draggable', draggableRef.value);
      rootEl.classList.add('draggable-handle-root');
    }

  });

  onBeforeUnmount(() => {
    const rootEl = rootRef.value?.$el || rootRef.value;
    if (rootEl) {
      rootEl.removeAttribute('draggable');
      rootEl.removeEventListener('dragstart', dragStart);
      rootEl.removeEventListener('dragend', dragEnd);

      rootEl.removeEventListener('mousedown', clickStart);
      rootEl.classList.remove('draggable-handle-root');
    }
  });

  return { isDragging, dragOffset };
}
