<template>
  <DialogActionPane class="crud-list">
    <ListContainer
        v-if="!!modelValue?.length"
        :items="modelValue"
        :id-field="idField">
      <template #item="{item}">
        <InteractiveListItem
            :enabled="interactive"
            @click="$emit('click', item)"
        >
          <div class="crud-list__item">
            <div class="crud-list__slot">
              <slot
                  name="item"
                  :item="item">
                <TextLabel
                    class="crud-list__label"
                    tag="div">
                  <slot
                      name="label"
                      :item="item" />
                </TextLabel>
              </slot>
            </div>
            <button
                v-if="canDelete"
                tabindex="0"
                type="button"
                :title="(confirm) ? t('remove') : t('removeSubmit')"
                class="crud-list__remove"
                @click="(confirm) ? handleConfirm(item) : remove(item)">
              <DeleteIcon />
              <span>{{ (confirm) ? t('remove') : t('removeSubmit') }}</span>
            </button>
          </div>
        </InteractiveListItem>
      </template>
    </ListContainer>
    <InsetPane
        v-if="!modelValue?.length && !!$slots.emptyLabel"
        type="small">
      <TextLabel>
        <slot name="emptyLabel" />
      </TextLabel>
    </InsetPane>
    <template
        v-if="!!$slots.actions"
        #actions>
      <slot name="actions" />
    </template>
    <template
        v-if="!!$slots.buttons || !!$slots.add"
        #buttons>
      <slot name="buttons" />
      <slot
          name="add"
          :add="add" />
    </template>
    <teleport to="#modal">
      <ModalContainer
          :open="confirmItem !== null"
          @update:open="confirmItem = null">
        <DialogPanel
            type="warning"
            :title="t('remove')"
            @close="confirmItem=null">
          <Paragraph>
            <slot
                name="removeWarning"
                :row="confirmItem">
              {{ t('removeWarning') }}
            </slot>
          </Paragraph>
          <template #ok>
            <IconButton
                type="destructive"
                :loading="removeLoading"
                @click="remove(confirmItem)">
              <template #icon>
                <DeleteIcon />
              </template>
              {{ t('removeSubmit') }}
            </IconButton>
          </template>
        </DialogPanel>
      </ModalContainer>
    </teleport>
  </DialogActionPane>
</template>
<i18n>
{
  "nl": {
    "removeSubmit": "Verwijder",
    "removeWarning": "Weet je zeker dat je dit wilt verwijderen?",
    "remove": "Verwijderen"
  },
  "en": {
    "removeSubmit": "Remove",
    "removeWarning": "Are you sure you want to remove this?",
    "remove": "Remove"
  }
}
</i18n>
<script>
import DeleteIcon from '@carbon/icons-vue/es/close/20.js';
import {
  ref,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import ListContainer from './ListContainer.vue';
import InteractiveListItem from './InteractiveListItem.vue';
import InsetPane from '../panels/InsetPane.vue';
import TextLabel from '../typography/TextLabel.vue';
import DialogActionPane from '../panels/DialogActionPane.vue';
import ModalContainer from '../modal/ModalContainer.vue';
import DialogPanel from '../modal/DialogPanel.vue';
import IconButton from '../buttons/IconButton.vue';
import Paragraph from '../typography/Paragraph.vue';

export default {
  components: {
    Paragraph,
    IconButton,
    DialogPanel,
    ModalContainer,
    DialogActionPane,
    TextLabel,
    InsetPane,
    ListContainer,
    InteractiveListItem,
    DeleteIcon,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    interactive: {
      type: Boolean,
      default: () => false,
    },
    canDelete: {
      type: Boolean,
      default: () => true,
    },
    confirm: {
      type: Boolean,
      default: () => false,
    },
    idField: {
      type: String,
      default: () => 'id',
    },
  },
  emits: ['update:model-value', 'click'],
  setup(props, { emit }) {
    const confirmItem = ref(null);
    const removeLoading = ref(false);

    const { t } = useI18n();
    const { modelValue, idField } = toRefs(props);
    return {
      confirmItem,
      removeLoading,

      t,

      handleConfirm(item) {
        confirmItem.value = item;
      },

      async add(item) {
        if (item) {
          const idx = (idField.value)
            ? modelValue.value.map((i) => i[idField.value]).indexOf(item[idField.value])
            : modelValue.value.indexOf(item);
          if (idx === -1) {
            emit('update:model-value', [...modelValue.value, item]);
          }
        }
      },
      async remove(item) {
        removeLoading.value = true;
        const idx = (idField.value)
          ? modelValue.value.map((i) => i[idField.value]).indexOf(item[idField.value])
          : modelValue.value.indexOf(item);
        if (idx > -1 && idField.value) {
          emit('update:model-value', modelValue.value.filter((i) => item[idField.value] !== i[idField.value]));
        } else if (idx > -1 && !idField.value) {
          emit('update:model-value', modelValue.value.filter((i) => item !== i));
        }
        removeLoading.value = false;
        confirmItem.value = null;
      },
    };
  },
};
</script>
<style>
.crud-list {

  &__remove {
    @media (any-hover: hover) {
      &:hover {
        color: var(--color-error);
        opacity: .25;
      }
    }
    margin: 0;
    padding: var(--dimension-small);

    color: inherit;
    background-color: transparent;
    border: none;
    opacity: 1;
    outline: none;
    cursor: pointer;

    transition: opacity var(--animation-default-duration) ease-in-out, color var(--animation-default-duration) linear;

    appearance: none;

    &:focus-within {
      opacity: .5;
    }

    > svg {
      display: inline-block;

      vertical-align: middle;
    }

    > span {
      display: none;
    }
  }

  &__item {
    @media (any-hover: hover) {
      &:has(.crud-list__remove:hover) {
        background-color: rgba(var(--rgb-error), .1);
      }
    }
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;

  }

  &__slot {
    flex: 1;
  }

  &__label {
    padding: var(--dimension-small);
  }
}
</style>
