<template>
  <div class="card-header">
    <div class="card-header__title">
      <slot name="title">
        <CardTitle>
          {{ title }}
        </CardTitle>
      </slot>
    </div>
    <div
        v-if="$slots.actions"
        class="card-header__actions">
      <slot name="actions" />
    </div>
  </div>
</template>
<script>
import CardTitle from './CardTitle.vue';

export default {
  components: { CardTitle },
  props: {
    title: {
      type: String,
      default: () => null,
    },
  },
};
</script>
<style>
.card-header {
  display: grid;
  grid-auto-flow: column;
  align-items: flex-start;

  padding: var(--dimension-small) var(--dimension-small) 0;

  &__title {
    display: grid;
    grid-auto-flow: row;
    gap: var(--dimension-x-small);
    align-items: center;
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    gap: var(--dimension-small);
    justify-content: flex-end;
    align-content: flex-end;
  }

}
</style>
