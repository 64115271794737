<template>
  <section
      class="tabbable-panel"
      :style="{
        '--tabbable-panel-columns': columns,
      }">
    <header class="tabbable-panel__header">
      <Header3 class="tabbable-panel__title">
        {{ title }}
      </Header3>
      <div
          v-if="!!$slots.actions"
          class="tabbable-panel__actions">
        <slot name="actions" />
      </div>
    </header>
    <ScrollablePane
        :contain="false"
        :disable-on-mobile="true"
        class="tabbable-panel__content">
      <slot />
    </ScrollablePane>
  </section>
</template>
<script>
import Header3 from '../typography/Header3.vue';
import ScrollablePane from '../panels/ScrollablePane.vue';

export default {
  components: { ScrollablePane, Header3 },
  props: {
    title: {
      type: String,
      default: () => null,
      required: true,
    },
    columns: {
      type: Number,
      default: () => 1,
    },
  },
};
</script>

<style>
.tabbable-panel {
  --tabbable-panel-columns: 1;

  display: flex;
  flex: 0 0 calc(var(--tabbable-panel-columns) * var(--tabbable-tab-width));
  flex-direction: column;

  min-height: 0;

  border-right: 1px solid var(--color-primary-complement-background);

  &:first-child:last-child {
    flex: 1;
  }

  &__header {
    display: flex;
    gap: var(--dimension-small);
    flex-direction: row;

  }

  &__title {
    flex: 1;

    padding: var(--dimension-small);

    color: var(--color-secondary-header);

    text-decoration: underline;
    text-decoration-color: var(--color-primary-header);
    text-decoration-style: solid;
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    gap: var(--dimension-x-small);
    align-items: center;

    padding: var(--dimension-x-small) var(--dimension-small);
  }

  &__content {
    flex: 1;
  }
}

/*
@media (max-width: 450px) {
  .tabbable-panel {
    flex: none;

    border-right: none;

    &__content {
      flex: none;
    }
  }

}
 */
</style>
