<template>
  <SearchSelectInput
      v-model:q="q"
      :model-value="modelValue"
      class="breed-search-select-input"
      :results="results"
      :loading="loading"
      :placeholder="t('searchUser')"
      max-height="8rem"
      @update:model-value="v => $emit('update:model-value', v)">
    <template #item="{item: breed}">
      <BreedLabel
          v-if="breed"
          :breed="breed" />
    </template>
  </SearchSelectInput>
</template>
<i18n>
{
  "nl": {
    "searchUser": "Zoek een ras"
  },
  "en": {
    "searchCombination": "Find a breed"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import {
  computed,
  ref,
} from 'vue';
import gql from 'graphql-tag';
import { useQuery } from '@urql/vue';
import SearchSelectInput from '../forms/SearchSelectInput.vue';
import BreedLabel, { BREED_LABEL_FRAGMENT } from './BreedLabel.vue';

export const BREEDS_QUERY = gql`
query Breeds {
  breeds {
    id
    ...BreedLabelFields
  }
}
${BREED_LABEL_FRAGMENT}`;

export default {
  components: {
    BreedLabel,
    SearchSelectInput,
  },
  props: {
    modelValue: {
      type: [Object, null],
      required: true,
    },
  },
  emits: ['update:model-value'],
  setup() {
    // i18n
    const { t } = useI18n();

    // Refs
    const q = ref('');

    const breedsQuery = useQuery({
      query: BREEDS_QUERY,
    });

    const results = computed(() => breedsQuery.data.value?.breeds.filter(
      (b) => b.fullName.toLocaleLowerCase().indexOf(q.value?.toLocaleLowerCase()) !== -1,
    ) || []);

    return {
      t,
      loading: breedsQuery.fetching,

      q,
      results,
    };
  },
};
</script>
