<template>
  <label
      class="check-box"
      :class="{
        'check-box--error': validator && validator.$error,
        'check-box--read-only': readOnly,
        'check-box--disabled': disabled,
        'check-box--narrow': narrow,
    }">
    <input
        :id="id"
        v-bind="$attrs"
        v-model="internalValue"
        :name="name"
        type="checkbox"
        class="check-box__input" >
    <slot
        name="content"
        :for="id">
      <TextLabel
          tag="span"
          class="check-box__label"
          :for="id">
        <slot :label="label">
          {{ label }}
        </slot>
      </TextLabel>
    </slot>
  </label>
</template>

<script>
import {
  computed,
  toRefs,
} from 'vue';
import Input from './Input.vue';
import TextLabel from '../../typography/TextLabel.vue';

export default {
  components: { TextLabel },
  inheritAttrs: false,
  props: {
    ...Input.props,
    modelValue: {
      type: Boolean,
      default: () => false,
    },
    id: {
      type: String,
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    narrow: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:modelValue', 'check', 'uncheck'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    return {
      internalValue: computed({
        get() {
          return modelValue.value;
        },
        set: (v) => {
          emit('update:modelValue', !!v);
        },
      }),
    };
  },
};
</script>

<style>
.check-box {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: var(--dimension-small);
  align-items: flex-start;

  padding: var(--dimension-small);

  cursor: pointer;

  &--disabled {
    opacity: .5;
    cursor: auto;
  }

  &--read-only {
    cursor: auto;

    pointer-events: none;
  }

  &--narrow {
    padding: 0;
  }

  &__label {
    padding: var(--dimension-x-small) 0;
  }

  &__input {
    @media (any-hover: hover) {
      &:hover {
        background-image: linear-gradient(
            to bottom,
            rgba(var(--rgb-default-foreground), .075) 0,
            rgba(var(--rgb-default-foreground), .075) 50%,
            var(--color-primary-background) 50%,
            var(--color-primary-background) 100%
        );

        background-position: bottom center;
      }
    }

    position: relative;

    width: var(--dimension-medium-large);
    height: var(--dimension-medium-large);
    margin: 0;

    color: var(--color-default-foreground);

    background-color: transparent;

    background-image: linear-gradient(
        to bottom,
        rgba(var(--rgb-default-foreground), .05) 0,
        rgba(var(--rgb-default-foreground), .05) 50%,
        var(--color-primary-background) 50%,
        var(--color-primary-background) 100%
    );
    background-position: top center;
    background-size: 100% calc(200% - 2px);
    border: inherit;
    outline: none;

    cursor: pointer;

    font-family: var(--font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;

    vertical-align: middle;

    transition: background-position var(--animation-default-duration) linear;

    appearance: none;

    &:focus-within:not(:hover) {
      background-image: linear-gradient(
          to bottom,
          rgba(var(--rgb-default-foreground), .075) 0,
          rgba(var(--rgb-default-foreground), .075) 50%,
          rgba(var(--rgb-primary-background), .5) 50%,
          rgba(var(--rgb-primary-background), .5) 100%
      );

      background-position: bottom center;
    }

    &--error {
      border-color: var(--color-red);
    }

    &--read-only {
      opacity: .5;

      pointer-events: none;
    }

    &::after {
      position: relative;
      display: block;
      content: "✅";

      width: 100%;
      height: 100%;

      color: transparent;

      background-color: rgba(var(--rgb-default-foreground), .15);
      /*noinspection CssUnknownTarget*/
      mask: url("@carbon/icons/svg/20/checkmark.svg");
      mask-repeat: no-repeat;
      mask-size: 1rem 1rem;
      mask-position: center center;

      transition: background-color calc(var(--animation-default-duration) / 2) linear;
    }

    &:checked {
      background-position: bottom center;

      &::after {
        background-color: var(--color-default-background);
      }
    }
  }

  &--disabled &__input {
    cursor: auto;
  }

  &--read-only &__input {
    background-image: linear-gradient(
        to bottom,
        rgba(var(--rgb-default-foreground), .05) 0,
        rgba(var(--rgb-default-foreground), .05) 100%
    );
    cursor: auto;

    &::after {
      background-color: var(--color-primary-background);
    }

    &:not(:checked)::after {
      mask: url("@carbon/icons/svg/32/subtract.svg");
      mask-repeat: no-repeat;
      mask-size: 1rem 1rem;
      mask-position: center center;
    }
  }

}

</style>
