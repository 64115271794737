<template>
  <section
    class="page-section">
    <header class="page-section__header">
      <div
        v-if="!!$slots.title"
        class="page-section__title">
        <slot
          name="title" />
      </div>
      <Header2
        v-else-if="title"
        class="page-section__title">
        {{ title }}
      </Header2>
      <div
        v-if="!!$slots.subtitle"
        class="page-section__title">
        <slot
          name="subtitle" />
      </div>
      <Header4
        v-else-if="subtitle"
        class="page-section__subtitle">
        {{ subtitle }}
      </Header4>

      <div
        v-if="!!$slots.actions"
        class="page-section__actions">
        <slot
          name="actions" />
      </div>
    </header>

    <slot />
  </section>
</template>
<script>
import Header2 from '../typography/Header2.vue';
import Header4 from '../typography/Header4.vue';

export default {
  components: {
    Header2,
    Header4,
  },
  props: {
    title: {
      type: String,
      default: () => null,
    },
    subtitle: {
      type: String,
      default: () => null,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
<style>
.page-section {
  padding: var(--dimension-page-padding);
}
</style>
