<template>
  <td
      class="table-footer"
      :class="{
        [`table-footer--${type}`]: !!type,
        'table-footer--narrow': narrow,
      }">
    <TextLabel>
      <slot />
    </TextLabel>
  </td>
</template>
<script>
import TextLabel from '../typography/TextLabel.vue';

export default {
  components: { TextLabel },
  props: {
    type: {
      type: String,
      default: () => null,
    },
    narrow: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
<style>
.table-footer {
  padding: var(--dimension-x-small) var(--dimension-small);

  text-align: left;

  &--currency,
  &--time {
    text-align: right;
    font-variant-numeric: tabular-nums;
  }

  &--center,
  &--integer {
    text-align: center;
  }

  &--right,
  &--end {
    text-align: right;
  }

  &--narrow {
    padding: var(--dimension-small) var(--dimension-x-small);
  }
}
</style>
