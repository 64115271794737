import EventEmitter from 'eventemitter3';
import { ref } from 'vue';

export default class DropContext extends EventEmitter {

  constructor() {
    super();
    this.item = ref(null);
    this.dragging = ref(false);
    this.offset = ref(null);
  }

  set(contentType, item, offset) {
    if (!this.dragging.value) {
      this.item.value = item;
      this.dragging.value = true;
      this.offset.value = offset;
    }
  }

  cancel() {
    if (this.dragging.value) {
      this.emit('drop', null, null);
      this.item.value = null;
      this.dragging.value = false;
      this.offset.value = null;
    }
  }

  drop(source) {
    if (this.dragging.value) {
      this.emit('drop', this.item.value, source);
      this.item.value = null;
      this.dragging.value = false;
      this.offset.value = null;
    }
  }
}
