<template>
  <TextLabel class="list-item-label">
    <slot />
  </TextLabel>
</template>
<script>
import TextLabel from '../typography/TextLabel.vue';

export default {
  components: { TextLabel },
};
</script>
<style>
.list-item-label {
  padding: var(--dimension-small);
}
</style>
