<template>
  <IconLabel class="dog-label">
    <template #icon>
      <DogIcon />
    </template>
    <span>
        {{ dog.callingName }}
        <span
          v-if="dog.primaryContact"
          class="dog-label__contact">
          – {{ dog.primaryContact.fullName }}
        </span>
    </span>
  </IconLabel>
</template>
<script>
import DogIcon from '@carbon/icons-vue/es/dog-walker/16.js';
import IconLabel from '../typography/IconLabel.vue';

export default {
  components: {
    IconLabel,
    DogIcon,
  },
  props: {
    dog: {
      type: Object,
      required: true,
    },
  },
};
</script>
