<template>
  <li
      class="check-list-item"
      :class="[`check-list-item--${state}`]">
    <Field :name="name">
      <template #default="{id, fieldName}">
        <router-link
            v-if="to"
            :to="to"
            class="check-list-item__link">
          <label
              class="check-list-item__item">
        <span class="check-list-item__state">
          <OKIcon v-if="value" />
          <ErrorIcon v-else-if="required" />
          <WarningIcon v-else />
        </span>
            <TextLabel
                tag="span"
                class="check-list-item__label"
                :for="id">
              <slot
                  v-if="value"
                  :id="id"
                  :field-name="fieldName"
                  name="ok" />
              <slot
                  v-else-if="required"
                  :id="id"
                  :field-name="fieldName"
                  name="error" />
              <slot
                  v-else
                  :id="id"
                  :field-name="fieldName"
                  name="warning" />
              <slot v-if="!$slots.ok" />
            </TextLabel>
            <ArrowIcon class="check-list-item__arrow" />
          </label>
        </router-link>
        <div
            v-else
            class="check-list-item__check">
          <label
              class="check-list-item__item">
        <span class="check-list-item__state">
          <OKIcon v-if="value" />
          <ErrorIcon v-else-if="required" />
          <WarningIcon v-else />
        </span>
            <TextLabel
                tag="span"
                class="check-list-item__label"
                :for="id">
              <slot
                  v-if="value"
                  :id="id"
                  :field-name="fieldName"
                  name="ok" />
              <slot
                  v-else-if="required"
                  :id="id"
                  :field-name="fieldName"
                  name="error" />
              <slot
                  v-else
                  :id="id"
                  :field-name="fieldName"
                  name="warning" />
              <slot v-if="!$slots.ok" />
            </TextLabel>
          </label>
        </div>
      </template>
    </Field>
  </li>
</template>
<script>
import OKIcon from '@carbon/icons-vue/es/checkmark/32.js';
import WarningIcon from '@carbon/icons-vue/es/warning--alt/32.js';
import ErrorIcon from '@carbon/icons-vue/es/close/32.js';
import ArrowIcon from '@carbon/icons-vue/es/arrow--right/24.js';

import {
  computed,
  toRefs,
} from 'vue';
import Field from '../fields/FormField.vue';
import TextLabel from '../typography/TextLabel.vue';

export default {
  components: {
    TextLabel,
    Field,
    OKIcon,
    WarningIcon,
    ErrorIcon,
    ArrowIcon,
  },
  props: {
    name: {
      type: String,
      default: () => null,
    },
    value: {
      type: Boolean,
      default: () => true,
    },
    to: {
      type: Object,
      default: () => undefined,
    },
    required: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props) {
    const { value, required } = toRefs(props);

    return {
      state: computed(() => {
        if (value.value) {
          return 'ok';
        }
        if (required.value) {
          return 'error';
        }
        return 'warning';
      }),
    };

  },
};
</script>
<style>
.check-list-item {
  @media (any-hover: hover) {
    &__link:hover {
      background-color: rgba(var(--rgb-black), .075);
    }

    &__link:hover &__arrow {
      opacity: 1;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    gap: var(--dimension-small);
    align-items: flex-start;

    padding: var(--dimension-small);

    color: var(--color-default-foreground);

    background-color: transparent;

  }

  &__link &__item {
    cursor: pointer;
  }

  &__label {
    padding: var(--dimension-x-small) 0;

  }

  &__link {
    color: inherit;

    text-decoration: none;
  }

  &__state {
    display: flex;
    justify-content: center;
    align-items: center;

    width: var(--dimension-medium-large);
    height: var(--dimension-medium-large);

    color: var(--color-default-foreground);

    transition: color var(--animation-default-duration) linear;

  }

  &__arrow {
    align-self: center;

    opacity: .25;
  }

  &--error &__state {
    color: var(--color-error);
  }

  &--warning &__state {
    color: var(--color-warning);
  }

  &--ok &__state {
    color: var(--color-success);
  }
}

</style>
