<template>
  <button
      class="panel-action"
      @click.prevent.stop="$emit('click')">
    <span class="panel-action__icon">
      <slot />
    </span>
    <TextLabel
        v-if="$slots.label"
        class="panel-action__label">
      <slot name="label" />
    </TextLabel>
  </button>
</template>
<script>
import TextLabel from '../typography/TextLabel.vue';

export default {
  components: { TextLabel },
  emits: ['click'],
};
</script>
<style>
.panel-action {
  --panel-action-background: var(--color-background);
  --panel-action-active-background: var(--color-default-background);
  @media (any-hover: hover) {
    &:hover {
      background-position: bottom center;
    }
  }

  display: flex;

  align-items: center;

  margin: 0;
  padding: 0;

  color: var(--color-default-foreground);

  background-color: transparent;

  background-image: linear-gradient(
      to bottom,
      var(--panel-action-background) 0,
      var(--panel-action-background) 50%,
      var(--panel-action-active-background) 50%,
      var(--panel-action-active-background) 100%
  );
  background-position: top center;
  background-size: 100% 200%;
  border: 0;
  outline: 0;
  cursor: pointer;

  transition: background-position var(--animation-default-duration);

  appearance: none;

  &__icon {
    display: flex;
    justify-content: center;

    align-items: center;

    width: 2rem;
    aspect-ratio: 1/1;
  }

  a {
    color: inherit;
  }
}
</style>
