import gql from 'graphql-tag';
import {
  addWeeks,
  endOfDay,
  isAfter,
  isBefore,
} from 'date-fns';
import { isWithinRange } from '../../utils/date.js';

export const ShowPhase = {
  CREATED: 'CREATED',
  REQUESTED: 'REQUESTED',
  BEFORE_PREREGISTRATION: 'BEFORE_PREREGISTRATION',
  PREREGISTRATION_OPEN: 'PREREGISTRATION_OPEN',
  BEFORE_REGISTRATION: 'BEFORE_REGISTRATION',
  REGISTRATION_OPENING: 'REGISTRATION_OPENING',
  REGISTRATION_OPEN: 'REGISTRATION_OPEN',
  REGISTRATION_CLOSED: 'REGISTRATION_CLOSED',
  REGISTRATION_FINALISED: 'REGISTRATION_FINALISED',
  DURING_SHOW: 'DURING_SHOW',
  AFTER_SHOW: 'AFTER_SHOW',
  SHOW_FINALISED: 'SHOW_FINALISED',
};

export const SHOW_PHASE_FRAGMENT = gql`
  fragment ShowPhaseFields on Show {
    id
    visible
    isFinalised
    usesTickets
    requestState
    registrationPeriod {
      start
      end
    }
    preregistrationPeriod {
      start
      end
    }
    
    event {
      id
      requestState
      usesPreregistration
      visible
      duration {
        start
        end
      }
    }
  }
`;

export function getShowPhaseOrder(showPhase) {
  return [
    ShowPhase.CREATED,
    ShowPhase.REQUESTED,
    ShowPhase.BEFORE_PREREGISTRATION,
    ShowPhase.PREREGISTRATION_OPEN,
    ShowPhase.BEFORE_REGISTRATION,
    ShowPhase.REGISTRATION_OPENING,
    ShowPhase.REGISTRATION_OPEN,
    ShowPhase.REGISTRATION_CLOSED,
    ShowPhase.REGISTRATION_FINALISED,
    ShowPhase.DURING_SHOW,
    ShowPhase.AFTER_SHOW,
    ShowPhase.SHOW_FINALISED,
  ].indexOf(showPhase);
}

export function getShowPhase(show) {

  if (show.requestState !== 'APPROVED') {
    return show.requestState;
  }

  const now = new Date();

  if (show.preRegistrationPeriod
    && show.event.usesPreregistration
    && isWithinRange(now, show.preRegistrationPeriod)) {
    return ShowPhase.PREREGISTRATION_OPEN;
  }
  if (show.preRegistrationPeriod
    && show.event.usesPreregistration
    && isBefore(now, show.preRegistrationPeriod.start)) {
    return ShowPhase.BEFORE_PREREGISTRATION;
  }

  if (show.registrationPeriod && isWithinRange(now, show.registrationPeriod)) {
    return ShowPhase.REGISTRATION_OPEN;
  }
  if (show.registrationPeriod && isWithinRange(endOfDay(now), show.registrationPeriod)) {
    return ShowPhase.REGISTRATION_OPENING;
  }

  if (show.registrationPeriod && isBefore(now, show.registrationPeriod.start)) {
    return ShowPhase.BEFORE_REGISTRATION;
  }

  if (show.isFinalised) {
    return ShowPhase.SHOW_FINALISED;
  }

  if (isWithinRange(now, show.duration)) {
    return ShowPhase.DURING_SHOW;
  }

  if (isAfter(now, show.duration.end)) {
    return ShowPhase.AFTER_SHOW;
  }

  if (show.registrationPeriod && isAfter(now, addWeeks(show.duration.start, -1))) {
    return ShowPhase.REGISTRATION_FINALISED;
  }

  return ShowPhase.REGISTRATION_CLOSED;

}

export function isShowBeforePhase(show, phase) {
  const currentPhase = getShowPhase(show);
  return getShowPhaseOrder(currentPhase) < getShowPhaseOrder(phase);
}

export function isShowDuringPhase(show, ...phases) {
  const currentPhase = getShowPhase(show);
  return phases.indexOf(currentPhase) !== -1;
}
