<template>
  <Popover
      v-model="open"
      :class="{'filter-field--open': open}"
      :close-on-outside-click="true"
      class="filter-field">
    <TextLabel
        tag="div"
        class="filter-field__label"
        @click="open = !open">
      <div class="filter-field__label-contents">
        <slot name="icon" />
        <slot name="label">
          {{ (value) ? value.name : '' }}
        </slot>
      </div>
      <DownIcon class="filter-field__down" />
    </TextLabel>

    <template #popover>
      <slot name="popover">
        <ol class="filter-field__options">
          <li
              v-for="option in options"
              :key="option.id"
              class="filter-field__option"
              @click="$emit('select', option)">
            <slot
                name="option"
                :option="option">
              {{ option.name }}
            </slot>
          </li>
        </ol>
      </slot>
    </template>
  </Popover>
</template>
<script>
import DownIcon from '@carbon/icons-vue/es/chevron--down/16.js';
import { useRoute } from 'vue-router';
import {
  ref,
  watch,
} from 'vue';
import Popover from '../legacy/Popover.vue';
import TextLabel from '../typography/TextLabel.vue';

export default {
  components: {
    TextLabel,
    Popover,
    DownIcon,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['select'],
  setup() {
    const route = useRoute();
    const open = ref(false);

    watch(route, () => {
      open.value = false;
    });
    return {
      open,
    };
  },
};
</script>
<style>

.filter-field {
  justify-self: flex-start;

  &__label {
    @media (any-hover: hover) {
      &:hover {
        border-bottom-color: var(--color-secondary-background);
      }
    }
    display: flex;
    gap: var(--dimension-x-small);
    flex-direction: row;

    padding-bottom: var(--dimension-thick-line);

    border-bottom: var(--dimension-thick-line) solid var(--color-secondary-complement);
    cursor: pointer;

    &-contents {
      flex: 1;
    }
  }

  &--open &__label {
    border-bottom-color: var(--color-secondary-background);
  }

  &__options {
    max-height: calc(100vh - 15rem);
    overflow-y: auto;
  }

  &__option {
    @media (any-hover: hover) {
      &:hover {
        background-color: var(--color-white);
      }
    }
    padding: var(--dimension-x-small);

    cursor: pointer;
  }
}

</style>
