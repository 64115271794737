<template>
  <IconLabel class="breed-label">
    <template #icon>
      <BreedIcon />
    </template>
    <span>
        {{ breed.fullName }}
    </span>
  </IconLabel>
</template>
<script>
import BreedIcon from '@carbon/icons-vue/es/chevron--right/16.js';
import gql from 'graphql-tag';
import IconLabel from '../typography/IconLabel.vue';

export const BREED_LABEL_FRAGMENT = gql`
fragment BreedLabelFields on Breed {
  fullName
}
`;

export default {
  components: {
    IconLabel,
    BreedIcon,
  },
  props: {
    breed: {
      type: Object,
      required: true,
    },
  },
};
</script>
