<template>
  <InteractiveTableRow
      class="selectable-table-row"
      :class="{
        'selectable-table-row--selected': selected,
        'selectable-table-row--enabled': enabled,
        'selectable-table-row--selected-enabled': selected && enabled,
      }"
      :row="row"
      :enabled="enabled"
      :index="index"
      @click="$emit('click', row)">
    <template #default="{row: r}">
      <slot :row="r" />
    </template>
  </InteractiveTableRow>
</template>
<script>
import InteractiveTableRow from './InteractiveTableRow.vue';

export default {
  components: { InteractiveTableRow },

  props: {
    ...InteractiveTableRow.props,
    selected: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['click'],
};
</script>
<style>
.selectable-table-row {
  --table-row-selected-background: var(--color-secondary-background);
  --table-row-selected-background-hover: rgba(var(--rgb-secondary-background), .5);
  @media (any-hover: hover) {
    &--enabled:hover {
      background-position: 0 33.3%;
    }
    &--selected-enabled:hover {
      background-position: 0 100%;
    }
  }

  background-color: transparent;
  background-image: linear-gradient(
      to bottom,
      var(--table-row-background) 0%,
      var(--table-row-background) 25%,
      color-mix(in srgb, var(--table-row-background), var(--color-default-foreground) 5%) 25%,
      color-mix(in srgb, var(--table-row-background), var(--color-default-foreground) 5%) 50%,
      var(--table-row-selected-background) 50%,
      var(--table-row-selected-background) 75%,
      var(--table-row-selected-background-hover) 75%,
      var(--table-row-selected-background-hover) 100%
  );
  background-position: 0 0;
  background-size: 100% 500%;

  transition: background-position calc(var(--animation-default-duration) / 2) ease-in-out, color calc(var(--animation-default-duration) / 2) ease-in-out;

  &:nth-child(2n) {
    background-image: linear-gradient(
        to bottom,
        var(--table-row-alternate-background) 0%,
        var(--table-row-alternate-background) 25%,
        color-mix(in srgb, var(--table-row-alternate-background), var(--color-default-background) 50%) 25%,
        color-mix(in srgb, var(--table-row-alternate-background), var(--color-default-background) 50%) 50%,
        var(--table-row-selected-background) 50%,
        var(--table-row-selected-background) 75%,
        var(--table-row-selected-background-hover) 75%,
        var(--table-row-selected-background-hover) 100%
    );
  }

  &--selected {
    background-position: 0 66.67%;
  }

}
</style>
