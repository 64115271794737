<template>
  <FieldSet>
    <template #title>
      {{ t('appearance') }}
    </template>
    <template v-if="!hasPedigree">
      <Paragraph type="intro">
        {{ t('appearanceIntro' ) }}
      </Paragraph>
      <BranchSelect
          v-model="mixType"
          name="mixType"
          option="similar">
        <template #label>
          {{ t('similar') }}
        </template>

        <FormField
            :validator="validator.breed"
            name="breed">
          <template #label>
            {{ t('breed') }}
          </template>
          <template #default="{ id, fieldName }">
            <ListInput
                :id="id"
                v-model="breed"
                :options="breedOptions"
                label-field="fullName"
                :name="fieldName" />
          </template>
          <template #error>
            <li v-if="validator.breed.required.$invalid">
              {{ t('required') }}
            </li>
          </template>
          <template
              v-if="breed"
              #help-text>
            {{ t('useBreed', { breed: breed.fullName.toLocaleLowerCase() }) }}
          </template>
        </FormField>
      </BranchSelect>

      <BranchSelect
          v-model="mixType"
          name="mixType"
          option="mix">
        <template #label>
          {{ t('mix') }}
        </template>

        <FieldSet>
          <FormField
              :validator="validator.breed"
              name="breed">
            <template #label>
              {{ t('breed') }}
            </template>
            <template #default="{ id, fieldName }">
              <ListInput
                  :id="id"
                  v-model="breed"
                  :options="breedOptions"
                  label-field="fullName"
                  :name="fieldName" />
            </template>
            <template #error>
              <li v-if="validator.breed.required.$invalid">
                {{ t('required') }}
              </li>
            </template>
          </FormField>
          <FormField
              :validator="validator.mixBreed"
              name="mixBreed">
            <template #label>
              {{ t('breed') }}
            </template>
            <template #default="{ id, fieldName }">
              <ListInput
                  :id="id"
                  v-model="mixBreed"
                  :options="mixBreedOptions"
                  label-field="fullName"
                  :name="fieldName" />
            </template>
            <template #error>
              <li v-if="validator.mixBreed.required.$invalid">
                {{ t('required') }}
              </li>
            </template>
            <template
                v-if="breed && mixBreed"
                #help-text>
              {{
                t('useMix', {
                  breed: breed.fullName.toLocaleLowerCase(),
                  mixBreed: mixBreed.fullName.toLocaleLowerCase(),
                })
              }}
            </template>
          </FormField>
        </FieldSet>
      </BranchSelect>

      <BranchSelect
          v-model="mixType"
          name="mixType"
          option="other">
        <template #label>
          {{ t('other') }}
        </template>

        <Paragraph>
          {{ t('useOther') }}
        </Paragraph>
      </BranchSelect>
    </template>
    <FormField
        v-else
        name="breed">
      <template #label>
        {{ t('breed') }}
      </template>
      <template #default="{ id, fieldName }">
        <ListInput
            :id="id"
            v-model="breed"
            :options="breedOptions"
            label-field="fullName"
            :name="fieldName" />
      </template>
    </FormField>
  </FieldSet>
</template>
<i18n>
{
  "nl": {
    "similar": "Lijkt sprekend op",
    "mix": "Kruising tussen",
    "other": "Anders",
    "breed": "Ras",
    "required": "Selecteer een ras",
    "appearance": "Rasomschrijving",
    "appearanceIntro": " We gebruiken een rasomschrijving om wedstrijdorganisaties een idee te geven welke hond ze voor zich gaan krijgen.",
    "mixBreed": "Kruising",
    "useBreed": "We gebruiken '{breed}' als omschrijving",
    "useMix": "We gebruiken '{breed} × {mixBreed}' als omschrijving",
    "useOther": "We gebruiken 'kruising' als omschrijving"
  },
  "en": {
    "similar": "Looks just like a(n)",
    "mix": "Mix between",
    "other": "Other",
    "breed": "Breed",
    "required": "Select a breed",
    "appearance": "Breed description",
    "appearanceIntro": " We use a breed description to give event organisers an idea what kind of dog to expect.",
    "mixBreed": "Mix",
    "useBreed": "We will use '{breed}' as description",
    "useMix": "We will use '{breed} × {mixBreed}' as description",
    "useOther": "We will use 'kruising' (Dutch for 'mix') as description"
  }
}
</i18n>
<script>
import gql from 'graphql-tag';
import { useQuery } from '@urql/vue';
import {
  computed,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import FieldSet from '../fieldsets/FieldSet.vue';
import ListInput from '../forms/ListInput.vue';
import FormField from '../fields/FormField.vue';
import { toModelValueRefs } from '../../utils/refs.js';
import BranchSelect from '../forms/BranchSelect.vue';
import Paragraph from '../typography/Paragraph.vue';

const BREED_QUERY = gql`
query Breeds {
  breeds {
    id
    fullName
  }
}
`;

export default {
  components: {
    Paragraph,
    BranchSelect,
    FormField,
    ListInput,
    FieldSet,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    hasPedigree: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const { modelValue, hasPedigree } = toRefs(props);
    const { breed, mixBreed } = toModelValueRefs(modelValue, emit, ['breed', 'mixBreed']);

    let mix = 'other';
    if (mixBreed.value && breed.value) {
      mix = 'mix';
    } else if (breed.value) {
      mix = 'similar';
    }
    const mixType = ref(mix);

    const breedQuery = useQuery({ query: BREED_QUERY });
    const breeds = computed(() => {
      const sortedBreeds = breedQuery.data.value?.breeds;
      if (sortedBreeds) {
        sortedBreeds.sort((a, b) => a.fullName.localeCompare(b.fullName));
        return sortedBreeds;
      }
      return [];
    });

    const validator = useVuelidate({
      breed: {
        required: requiredIf(computed(
          () => mixType.value === 'similar'
              || mixType.value === 'mix'
            || !!hasPedigree.value,
        )),
      },
      mixBreed: { required: requiredIf(computed(() => mixType.value === 'mix')) },
    }, { breed, mixBreed });

    watch(mixType, (mt) => {
      if (mt === 'similar' && mixBreed.value) {
        mixBreed.value = null;
      } else if (mt === 'other' && (mixBreed.value || breed.value)) {
        mixBreed.value = null;
        breed.value = null;
      }
    });

    return {
      t,

      validator,
      mixType,

      breed,
      mixBreed,

      breedOptions: computed(
        () => breeds.value?.filter((b) => !mixBreed.value || b.id !== mixBreed.value.id),
      ),
      mixBreedOptions: computed(
        () => breeds.value?.filter((b) => !breed.value || b.id !== breed.value.id),
      ),
      loading: computed(() => breedQuery.fetching.value),
    };
  },
};
</script>
