<template>
  <TextLabel
      tag="div"
      class="empty-pane">
    <slot />
  </TextLabel>
</template>
<script>
import TextLabel from '../typography/TextLabel.vue';

export default {
  components: { TextLabel },
};
</script>
<style>
.empty-pane {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: var(--dimension-small);

  background-color: rgba(var(--rgb-primary), .25);

  font-style: italic;
}
</style>
