<template>
  <FieldSet>
    <template v-if="isGuest">
      <Paragraph>
        {{ t('introGuest') }}
      </Paragraph>
      <FormField
          name="recordBookFiles"
          :validator="validator.recordBookFiles">
        <template #label>
          {{ t('recordBookFiles') }}
        </template>
        <template #default="{id, fieldName}">
          <FilesInput
              :id="id"
              v-model="validator.recordBookFiles.$model"
              :name="fieldName"
              :multiple="false"
              :maximum-size="2000000"
              :accept="['image/jpeg']" />
        </template>
        <template #help-text>
          {{ t('recordBookFilesHelpText') }}
        </template>

        <template #error>
          <li v-if="validator.recordBookFiles.$invalid">
            {{ t('recordBookFilesRequired') }}
          </li>
        </template>
      </FormField>
    </template>

    <template v-else>
      <Paragraph>
        {{ t('intro') }}
      </Paragraph>
      <Paragraph>
        <IconButton
            type="secondary"
            to="https://www.houdenvanhonden.nl/actief-met-je-hond/sporten-met-je-hond/hondenlogboek/">
          {{ t('recordBookLink') }}
        </IconButton>
      </Paragraph>
      <FormField
          name="recordBookReceived"
          :validator="validator.recordBookReceived">
        <template #default="{id, fieldName}">
          <CheckBox
              :id="id"
              v-model="validator.recordBookReceived.$model"
              :name="fieldName">
            {{ t('confirmRecordBookReceived') }}
          </CheckBox>
        </template>
        <template #error>
          <li v-if="validator.recordBookReceived.$invalid">
            {{ t('recordBookReceivedRequired') }}
          </li>
        </template>
      </FormField>
    </template>
  </FieldSet>
</template>
<i18n>
{
  "nl": {
    "recordBookFiles": "Document",
    "recordBookFilesRequired": "Selecteer een bestand",
    "recordBookFilesHelpText": "Bestand mag maximaal 2MB groot zijn en moet het JPG-formaat hebben.",
    "intro": "Voordat we de hond kunnen registreren, moet de eigenaar van de hond een hondenlogboek in het bezit hebben. Zonder dit logboek kan je niet deelnemen aan wedstrijden. Deze moet reeds ontvangen zijn en mag niet nog in aanvraag zijn.",
    "confirmRecordBookReceived": "De eigenaar van de hond heeft een hondenlogboek aangevraagd en ontvangen.",
    "recordBookReceivedRequired": "Zonder een hondenlogboek kunnen wij de hond nog niet registreren, regel dit eerst.",
    "recordBookLink": "Open website aanvragen hondenlogboek in nieuw venster",
    "introGuest": "We vragen je een kopie te maken van een bewijs dat je ingeschreven staat bij een in jouw land aangesloten FCI-organisatie. Dit mag een foto zijn van een logboek, een pas, of een screenshot in geval gewerkt wordt met een digitale administratie."
  },
  "en": {
    "recordBookFiles": "Document",
    "recordBookFilesRequired": "Select a document",
    "recordBookFilesHelpText": "Document should be a JPG image and at most 2MB",
    "intro": "Before we can register the dog, the owner of the dog must have a record book for this dog. You can not compete in shows without it.",
    "confirmRecordBookReceived": "The owner of the dog has requested and received a record book.",
    "recordBookReceivedRequired": "We cannot register the dog without a record book, make sure you have one first.",
    "recordBookLink": "Open record book website",
    "introGuest": "We ask you to make a copy of a record of registration with the FCI kennel club in the country you live in. This can be a record book, a registration card or a screenshot of a digital registration."
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import { toRefs } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import gql from 'graphql-tag';
import FieldSet from '../fieldsets/FieldSet.vue';
import Paragraph from '../typography/Paragraph.vue';
import FormField from '../fields/FormField.vue';
import CheckBox from '../widgets/forms/CheckBox.vue';
import { toModelValueRefs } from '../../utils/refs.js';
import IconButton from '../buttons/IconButton.vue';
import FilesInput from '../widgets/forms/FilesInput.vue';

export const RECORD_BOOK_REGISTER_MUTATION = gql`
mutation RecordBookRegister($dog: ID!, $hasRecordBook: Boolean, $recordBook: Upload) {
  dogRegisterRecordBook(dogId: $dog, hasRecordBook: $hasRecordBook, recordBook: $recordBook) {
    ... on OperationResponse {
      field
      kind
      messages
      fields {
        field
        messages
        kind
      }
    }
    ... on Dog {
      id
      hasRecordBook
      inReview
    }
  }
}
`;

export default {
  components: {
    FilesInput,
    IconButton,
    CheckBox,
    FormField,
    Paragraph,
    FieldSet,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    isGuest: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const { modelValue, isGuest } = toRefs(props);
    const {
      recordBookReceived,
      recordBookFiles,
    } = toModelValueRefs(modelValue, emit, ['recordBookReceived', 'recordBookFiles']);

    const validator = useVuelidate(
      {
        recordBookReceived: { required: (v) => v || isGuest.value },
        recordBookFiles: { required: (v) => v?.length === 1 || !isGuest.value },
      },
      {
        recordBookReceived,
        recordBookFiles,
      },
    );

    return {
      t,
      validator,

      recordBookReceived,
      recordBookFiles,
    };
  },
};
</script>
