<template>
  <FieldSet>
    <template #title>
      <slot name="title" />
    </template>
    <slot name="intro" />
    <FormField
        name="club"
        :validator="validator.club">
      <template #label>
        {{ t('club') }}
      </template>
      <template #default="{id, fieldName}">
        <ClubSelect
            :id="id"
            v-model="validator.club.$model"
            :name="fieldName"
            :validator="validator.club" />
      </template>
    </FormField>
    <FormField
        name="membershipNumber"
        :validator="validator.membershipNumber">
      <template #label>
        {{ t('membershipNumber') }}
      </template>
      <template #default="{id, fieldName}">
        <TextInput
            :id="id"
            v-model="validator.membershipNumber.$model"
            :name="fieldName"
            :size="20"
            :validator="validator.membershipNumber" />
      </template>
      <template #error>
        <li v-if="validator.membershipNumber.$invalid">
          {{ t('numberLength') }}
        </li>
      </template>
    </FormField>
  </FieldSet>
</template>
<i18n>
{
  "nl": {
    "membershipNumber": "Referentie",
    "numberLength": "Dit veld mag maximaal 20 tekens bevatten",
    "club": "Vereniging"
  },
  "en": {
    "membershipNumber": "Reference",
    "numberLength": "This has a maximum of 20 characters",
    "club": "Club"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import {
  maxLength,
  required,
} from '@vuelidate/validators';
import { toRefs } from 'vue';
import FieldSet from '../fieldsets/FieldSet.vue';
import FormField from '../fields/FormField.vue';
import { toModelValueRefs } from '../../utils/refs.js';
import TextInput from '../widgets/forms/TextInput.vue';
import ClubSelect from '../widgets/forms/ClubSelect.vue';

export default {
  components: {
    ClubSelect,
    TextInput,
    FormField,
    FieldSet,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => null,
    },
    externalResults: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { modelValue, externalResults } = toRefs(props);
    const { club, membershipNumber } = toModelValueRefs(modelValue, emit, ['club', 'membershipNumber']);
    const validator = useVuelidate({
      club: { required },
      membershipNumber: { maxLength: maxLength(20) },
    }, {
      club,
      membershipNumber,
    }, { $externalResults: externalResults });
    return {
      t,
      validator,

      club,
      membershipNumber,
    };
  },
};
</script>
