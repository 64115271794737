<template>
  <IconLabel class="combination-icon-label">
    <template #icon>
      <CombinationIcon />
    </template>
    <slot name="label">
      <HeaderTitle tag="span">
        {{ combination.handler.fullName }}
      </HeaderTitle>
      +
      <span style="white-space: nowrap">
        {{ combination.dog.callingName }}
      </span>
      <span
          v-if="showNumber && combination.number"
          class="combination-icon-label__number">
      {{ combination.number }}
    </span>
      <slot />
    </slot>
  </IconLabel>
</template>
<script>
import CombinationIcon from '@carbon/icons-vue/es/dog-walker/16.js';
import IconLabel from '../typography/IconLabel.vue';
import HeaderTitle from '../typography/HeaderTitle.vue';

export default {
  components: {
    HeaderTitle,
    IconLabel,
    CombinationIcon,
  },
  props: {
    combination: {
      type: Object,
      required: true,
    },
    showNumber: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
<style>
.combination-icon-label {
  &__number {
    font-weight: 500;

    user-select: text;
  }
}
</style>
