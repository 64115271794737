<template>
  <ListItem
      class="interactive-list-item"
      :class="{'interactive-list-item--enabled': enabled}"
      @click="(enabled) ? $emit('click') : undefined">
    <slot />
  </ListItem>
</template>
<script>
import ListItem from './ListItem.vue';

export default {
  components: { ListItem },
  props: {
    enabled: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['click'],
};
</script>
<style>

.interactive-list-item {
  --interactive-list-item-hover-background: rgba(var(--rgb-background), .5);
  --interactive-list-item-alternative-hover-background: rgba(var(--rgb-default-background), .5);
  @media (any-hover: hover) {
    &--enabled:hover {
      background-position: 0 35%;
    }
  }

  background-color: transparent;
  background-image: linear-gradient(
      to bottom,
      var(--list-item-background) 0%,
      var(--list-item-background) 25%,
      var(--interactive-list-item-hover-background) 25%,
      var(--interactive-list-item-hover-background) 50%,
      var(--interactive-list-item-hover-background) 50%,
      var(--interactive-list-item-hover-background) 75%,
      var(--color-link) 75%,
      var(--color-link) 100%
  );
  background-position: 0 0;
  background-size: 100% 500%;

  transition: background-position var(--animation-default-duration) ease-in-out, color var(--animation-default-duration) ease-in-out, transform var(--animation-default-duration) ease-in-out;

  &--enabled {
    cursor: pointer;
  }

  &:nth-child(2n + 1) {
    background-image: linear-gradient(
        to bottom,
        var(--list-item-alternate-background) 0%,
        var(--list-item-alternate-background) 25%,
        var(--interactive-list-item-alternative-hover-background) 25%,
        var(--interactive-list-item-alternative-hover-background) 50%,
        var(--interactive-list-item-alternative-hover-background) 50%,
        var(--interactive-list-item-alternative-hover-background) 75%,
        var(--color-link) 75%,
        var(--color-link) 100%
    );
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --interactive-list-item-hover-background: rgba(var(--rgb-default-background), .5);
    --interactive-list-item-alternative-hover-background: rgba(var(--rgb-background), .5);
  }
}
</style>
