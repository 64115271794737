<template>
  <FileDropPane
      class="files-input"
      :accept="accept"
      :maximum-size="maximumSize"
      @drop="dropFiles">
    <ul class="files-input__files">
      <li
          v-for="file in modelValue"
          :key="file.name"
          class="files-input__file">
        <a
            v-if="file.url"
            :href="file.url"
            class="files-input__link"
            target="_blank"
            rel="noopener">
          <PDFIcon v-if="file.name.toLowerCase().endsWith('.pdf')" />
          <DocumentIcon v-else />
          <TextLabel>{{ file.name }} ({{ bytesFormatter.format(file.size / 1000000) }} MB)</TextLabel>
          <RemoveIcon
              class="files-input__link-remove"
              @click.stop.prevent="removeFile(file)" />
        </a>
        <span
            v-else
            class="files-input__link files-input__link--new">
          <DocumentAddIcon />
          <TextLabel>{{ file.name }} ({{ bytesFormatter.format(file.size / 1000000) }} MB)</TextLabel>
          <RemoveIcon
              class="files-input__link-remove"
              @click.stop.prevent="removeFile(file)" />
        </span>
      </li>
      <li
          v-if="!modelValue || modelValue.length === 0"
          class="files-input__empty">
        <TextLabel v-if="multiple">
          {{ t('noFiles') }}
        </TextLabel>
        <TextLabel v-else>
          {{ t('noFile') }}
        </TextLabel>
      </li>
    </ul>
    <IconButton
        type="action"
        @click="input.click()">
      <template #icon>
        <AddIcon />
      </template>
      <template v-if="multiple">
        {{ t('addFile') }}
      </template>
      <template v-else>
        {{ t('selectFile') }}
      </template>
    </IconButton>

    <input
        :id="id"
        ref="input"
        :name="name"
        :accept="accept.join(',')"
        type="file"
        :multiple="multiple"
        class="files-input__input"
        @change="dropFile">
  </FileDropPane>
</template>
<i18n>
{
  "nl": {
    "noFiles": "Geen bestanden",
    "noFile": "Geen bestand geselecteerd",
    "addFile": "Voeg bestand toe",
    "selectFile": "Selecteer bestand"
  },
  "en": {
    "noFiles": "No files",
    "noFile": "No file selected",
    "addFile": "Add file",
    "selectFile": "Select file"
  }
}
</i18n>
<script>
import PDFIcon from '@carbon/icons-vue/es/document--pdf/20';
import DocumentIcon from '@carbon/icons-vue/es/document/20';
import AddIcon from '@carbon/icons-vue/es/add/20';
import RemoveIcon from '@carbon/icons-vue/es/close/20';
import DocumentAddIcon from '@carbon/icons-vue/es/document--add/20';
import {
  computed,
  ref,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import TextLabel from '../../typography/TextLabel.vue';
import IconButton from '../../buttons/IconButton.vue';
import FileDropPane from './FileDropPane.vue';

export default {
  components: {
    FileDropPane,
    IconButton,
    TextLabel,
    PDFIcon,
    DocumentIcon,
    AddIcon,
    RemoveIcon,
    DocumentAddIcon,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: () => undefined,
    },
    id: {
      type: String,
      default: () => undefined,
    },
    accept: {
      type: Array,
      default: () => ['application/pdf'],
    },
    maximumSize: {
      type: Number,
      default: () => 2000000,
    },
    multiple: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const input = ref(null);

    const {
      accept, maximumSize, modelValue, multiple,
    } = toRefs(props);

    const dropFiles = (droppedFiles) => {
      const files = [...droppedFiles].filter(
        (file) => accept.value.some((a) => file.type === a)
              && file.size <= maximumSize.value,
      );

      const value = modelValue.value?.slice() || [];
      value.push(...files.filter((f) => !value.find((ff) => ff.name === f.name)));
      if (!multiple.value) {
        emit('update:model-value', value.slice(value.length - 1));
      } else {
        emit('update:model-value', value);
      }
    };

    return {
      t,
      input,

      bytesFormatter: computed(() => new Intl.NumberFormat(locale.value, {
        style: 'decimal',
        notation: 'compact',
      })),

      dropFile(ev) {
        dropFiles(ev.target.files);
      },
      dropFiles,
      removeFile(file) {
        emit('update:model-value', modelValue.value?.filter((f) => f !== file));
      },
    };
  },
};
</script>
<style>

.files-input {
  display: grid;
  grid-gap: var(--dimension-medium);
  grid-template-rows: 1fr auto;

  min-width: 20ch;
  padding: var(--dimension-small);

  &__input {
    position: absolute;

    width: 0;
    height: 0;

    opacity: 0;

    appearance: none;
  }

  &__files {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: var(--dimension-x-small);
  }

  &__link {
    display: grid;
    grid-gap: var(--dimension-x-small);
    grid-template-columns: auto 1fr auto;
    align-items: center;

    padding: var(--dimension-x-small);

    color: inherit;

    background-color: rgba(var(--rgb-black), .1);
    border: var(--dimension-line) solid rgba(var(--rgb-black), .25);

    cursor: pointer;

    text-decoration: none;

    &--new {
      background-color: rgba(var(--rgb-primary), .25);
      border-color: rgba(var(--rgb-primary), .5);

      cursor: default;
    }
  }

  &__link-remove {
    @media (any-hover: hover) {
      &:hover {
        background-color: rgba(var(--rgb-black), .1);
        border: var(--dimension-line) solid rgba(var(--rgb-black), .25);
      }
    }
    cursor: pointer;
  }
}
</style>
