<template>
  <TextLabel class="combination-label">
    <slot name="label">
      <HeaderTitle tag="span">
        {{ combination.handler.fullName }}
      </HeaderTitle>
      +
      <span style="white-space: nowrap">
        {{ combination.dog.callingName }}
      </span>
      <span
          v-if="showNumber && combination.number"
          class="combination-label__number">
      {{ combination.number }}
    </span>
      <span
          v-if="combination.handler.country"
          class="combination-label__country">
      {{ combination.handler.country.id }}
    </span>
      <slot />
    </slot>
  </TextLabel>
</template>
<script>
import gql from 'graphql-tag';
import HeaderTitle from '../typography/HeaderTitle.vue';
import TextLabel from '../typography/TextLabel.vue';

export const COMBINATION_LABEL_FRAGMENT = gql`
fragment CombinationLabelFields on Combination {
  dog {
    id
    callingName
  }
  handler {
    id
    fullName
  }
  number
}
`;

export default {
  components: {
    TextLabel,
    HeaderTitle,
  },
  props: {
    combination: {
      type: Object,
      required: true,
    },
    showNumber: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
<style>
.combination-label {
  &__number {
    font-weight: 500;

    user-select: text;
  }

  &__country {
    display: inline-block;

    margin-left: var(--dimension-xx-small);

    color: rgba(var(--rgb-default-foreground), .75);

    font-size: .5rem;

    font-weight: 500;
  }
}
</style>
